//
//
//
//
//
//
//
//
//

import NavBar from '../components/pages/NavBar'
import SideBar from '../components/pages/SideBar'

export default {
  data() {
    return {
      ready: false
    }
  },
  components: {
    NavBar,
    SideBar
  },
  mounted() {
    this.request('get', `${this.$config.baseURL}/api/v1/auth/validate_token`, null, (response) => {
      if (!response || response.status == 401){
        this.$router.push('/login');
        this.ready = false;
      } else {
        this.ready = true;
      }
    });
  }
}
