import Vue from 'vue';
import VueFlashMessage from '@smartweb/vue-flash-message';

Vue.use(VueFlashMessage);

Vue.mixin({
  methods: {
    show_flash_message(status, message) {
      this.flashMessage.show({
        status: status,
        message: message,
        time: 3000,
        blockClass: 'custom-flash-message',
        x: 15,
        y: 58
      });
    }
  }
})
