//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  mounted() {
    let eventItems = this.$refs["eventlist"].children
    for (let i = 0; i < eventItems.length; i++) {
      if (this.$route.name.includes(eventItems[i].dataset.value)) {
        eventItems[i].className += ' actived'
      }
    }
  }
};
