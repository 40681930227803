import Vue from 'vue'
import axios from "axios";

Vue.mixin({
  methods: {
    pagination () {
      $('.data-table, .message-table, .delivered-message, .draff-message, .waiting-message, .user-table').DataTable({
        lengthMenu: [[20, 40, -1], [20, 40, "All"]],
        retrieve: true,
        paging : true,
        lengthChange: false,
        searching: false,
        ordering: true,
        info: true,
        autoWidth: false,
        stateSave: true,
        scrollX: true,
        scrollY: false,
        scrollCollapse: true,
        pagingType: 'simple',
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row justify-content-center'<'pagination-custom'ip>>",
        language: {
          info: "_START_ - _END_ of _TOTAL_",
          infoEmpty: "0 - 0 of 0",
          paginate: {
              first: "|<",
              last: ">|",
              previous: "<",
              next: ">"
          },
          emptyTable: "該当するデータはありません。"
        },
      })
    },
    datepicker () {
      $(".search-date").daterangepicker({
        format: 'mm/dd/yyyy',
        autoclose: true,
        todayBtn: false,
        timePicker: true,
        singleDatePicker: true
      });
    },
    authenticated (response) {
      if (response.status == 401){
        this.$router.push('/login')
      }
    },
    request (method, url, data = null, callback = function(){}) {
      let responseType = 'json';
      if (method == 'get_csv') { method = 'get'; responseType = 'blob'; }
      if (method == 'post_csv') { method = 'post'; responseType = 'blob'; }
      let optionAxios = {
        headers: {
          'access-token': localStorage.getItem('access-token'),
          'client': localStorage.getItem('client'),
          'uid': localStorage.getItem('uid'),
          'Access-Control-Allow-Origin': '*'
        },
        responseType: responseType
      }
      switch(method) {
        case 'get':
          this.$axios.get(url,
                        optionAxios
                      ).then(response =>
                        callback(response)
                      ).catch(error => {
                        callback(error.response)
                      });
          break;
        case 'post':
          axios.post(url, data, optionAxios).then(response =>
                                              callback(response)
                                            ).catch(error => {
                                              callback(error.response)
                                            });;
          break;
        case 'patch':
          axios.patch(url, data, optionAxios).then(response =>
                                               callback(response)
                                             ).catch(error => {
                                               callback(error.response)
                                             });;
          break;
        case 'put':
          axios.put(url, data, optionAxios).then(response =>
                                             callback(response)
                                           ).catch(error => {
                                             callback(error.response)
                                           });;
          break;
        case 'delete':
          axios.delete(url, optionAxios);
          break;
        default:
      }
    },
    maskUid(fullString) {
      const last4Digits = fullString.slice(-4);
      const maskedString = last4Digits.padStart(fullString.length, '●');
      return maskedString;
    },
    YYYYMMDD(date) {
      return moment(date).format('YYYY/MM/DD')
    },
    YYYYMMDDHHmmss(date) {
      return moment(date).format('YYYY/MM/DD HH:mm:ss')
    },
    moneyFormat(money) {
      return money?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    displayStatus(status) {
      const STATUS_NOT_DELIVERED = 1
      const STATUS_NOT_ARRIVED = 2
      const STATUS_UNREAD = 3
      const STATUS_READ_ALREADY = 4

      let display_status = ''
      switch (parseInt(status)) {
        case STATUS_NOT_DELIVERED: display_status = '未配信'; break;
        case STATUS_NOT_ARRIVED: display_status = '未到達'; break;
        case STATUS_UNREAD: display_status = '未読'; break;
        case STATUS_READ_ALREADY: display_status = '既読'; break;
      }
      return display_status;
    },
    format_display(row, column) {
      if (!row || !column) { return; }

      let content = row[column.name_en];
      if (!content) { return; }

      if (column.name_en == 'user_id') {
        return this.maskUid(content);
      } else if (column.name_en.includes('premium')) {
        return this.moneyFormat(content);
      } else if (column.name_en == 'csa_dwh_created_at') {
        return this.YYYYMMDDHHmmss(new Date(content));
      } else if (column.name_en == 'registration_date') {
        return this.YYYYMMDD(new Date(content));
      } else if (column.type.includes('varchar')) {
        return content;
      } else if (column.type.includes('date')) {
        return this.YYYYMMDD(content);
      // } else if (column.type.includes('timestamp')) {
      //   return this.YYYYMMDD(content);
      } else if (column.type.includes('int')) {
        return content.toString();
      } else {
        return content;
      }
    },
  }
})
