//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'moment/locale/ja'
import moment from 'moment'

let globalVariable = localStorage;

export default {
  data() {
    return {
      username: localStorage.getItem('username') || 'No Name',
      confirm_requests: {},
      confirm_requests_count: 0
    }
  },
  methods: {
    async logout() {
      let localStorage = this.$route.params.localStorage || globalVariable
      let accessToken = localStorage.getItem('access-token')
      let client = localStorage.getItem('client')
      let uid = localStorage.getItem('uid')

      await this.$auth.logout(
        {
          headers: {
            'access-token': accessToken,
            client: client,
            uid: uid
          }
        }).then(response => {
          localStorage.setItem('access-token', null)
          localStorage.setItem('client', null)
          localStorage.setItem('uid', null)
          localStorage.setItem('username', null)
        });

      this.$router.push('/login');
    },
    toDetail(message_id) {
      this.$router.push(`/distribution/distribution-detail/${message_id}`);
    },
    fetchConfirmation() {
      this.request('get', `${this.$config.baseURL}/api/v1/confirmation_requests/`, null, response => {
        this.confirm_requests = response.data.data.confirm_requests;
        this.confirm_requests_count = response.data.data.count;
      });
    },
    deleteConfirmation (id) {
      let url = `${this.$config.baseURL}/api/v1/confirmation_requests/${id}`;
      this.request('delete', url);
      setTimeout(() => this.fetchConfirmation(), 500);
    },
    fromNow(date) {
      return moment(date).fromNow();
    }
  },
  mounted() {
    this.fetchConfirmation();
  },
}
